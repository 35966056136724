export interface Applicant {
  broker: string;
  openBankingId: string;
  referenceId: string;
  loginName: string;
  loanGoal: number;
  status: string;
  createdAt: Date;
  modifiedAt: Date;
  lastEmailSentTime: Date;
}

export interface UserGoalDetailResponse {
  lastEmailSentTime: Date;
  status: string;
  enabledApplicants: Applicant[];
  jointAccountEnabled: boolean;
}

export interface Balance {
  amount?: number;
  goal: number;
}

export interface RapidRepayToken {
  token: string;
  timeStamp: string;
  rootUrl: string;
  libraryRootUrl: string;
}

export enum GoalStatus {
  None,
  Pending,
  Registered,
  Achieved,
}

export enum TrackingType {
  None,
  Savings,
  Loan
}

export interface LoanAccount {
  accountNumber: string;
  accountName: string;
  loanBalance: number;
  lenderName: string;
  repaymentFrequency: string;
  loanName: string;
  originationDate: string;
  lastRepaymentDate: string;
  currentInterestRate: number;
  termRemaining: string;
  lastRepaymentAmount?: number;
  nextRepaymentDate: string;
  minimumRepaymentAmount?: number;
}