import Axios from 'axios';

import { getConfigs } from '../config';

export const axios = Axios.create({
  baseURL: getConfigs().apiUrl,
});

axios.interceptors.request.use(
  (request: any) => {
    request.baseURL = getConfigs().apiUrl;
    request.headers.Authorization = `Bearer ${getConfigs().token}`;
    return request;
  },
  (error: any) => {
    const message = error.request?.data?.message || error.message;
    console.error(message);

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response: any) => {
    return response.data;
  },
  (error: any) => {
    const message = error.response?.data?.message || error.message;
    console.error(message);
    return Promise.reject(error);
  }
);
