import { useState, useRef, useEffect } from "react";
import { getRapidRepayToken } from "../../applicant/api/getApplicantGoalInfo";
import '../css/openBanking.css';

import styled from 'styled-components';
import { getConfigs, RapidRepayType } from "../../../config";

const CardBody = styled.div`
    border: 0;
    height: calc(100vh - 145px);
`;

const Footer = styled.div`
    text-align: left;
    margin-left: 15px;
    margin-top: 20px;
    padding: 15px 0 30px;
`;

export const RapidRepay = () => {
    const configs = getConfigs();
    const [token, setToken] = useState('');
    const [timestamp, setTimestamp] = useState('');
    const [rapidRepayUrl, setRapidRepayUrl] = useState('');
    const formRef = useRef<HTMLFormElement>(null); // Reference to form

    const handleSubmit = async () => {
        const response = await getRapidRepayToken();
        setTimestamp(response.timeStamp);
        setToken(response.token);
        if (configs.rapidRepayType === RapidRepayType.Launch) {
            setRapidRepayUrl(response.rootUrl);
        }
        else if(configs.rapidRepayType === RapidRepayType.Library){
            setRapidRepayUrl(response.libraryRootUrl);
        }
    };

    // Trigger handleSubmit on component mount
    useEffect(() => {
        handleSubmit();
    }, []);

    // Automatically submit form when token and timestamp are updated
    useEffect(() => {
        if (token && timestamp && formRef.current) {
            formRef.current.submit();
        }
    }, [token, timestamp]);

    return (
        configs.rapidRepayType ?
            <div >
                <CardBody>
                    <iframe
                        title="Rapid Repay"
                        name="child_frame"
                        style={{ width: '100%', height: '100%' }}
                    ></iframe>
                </CardBody>

                <Footer>
                    <form
                        ref={formRef}
                        method="post"
                        target="child_frame"
                        action={`${rapidRepayUrl}/${configs.leadId}`}
                    >
                        <input type="hidden" value={configs.applicants[0]?.applicantId} name="applicant" />
                        <input type="hidden" value="rapid_repay_report" name="operation" />
                        <input type="hidden" value={token} name="token" />
                        <input type="hidden" value={timestamp} name="timeStamp" />
                    </form>
                </Footer>
            </div>
            : <></>
    );
}
