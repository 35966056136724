import { forwardRef, useImperativeHandle } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export interface NotificationConfig {
    success(message: string): void;
    error(message: string): void;
  }

export const Notification = forwardRef<NotificationConfig, any>((props: any, ref) => {

    useImperativeHandle(ref, () => ({
        success(message) {
            toast.success(message);
        },
        error(message) {
            toast.error(message);
        }
      }));
    
    return (
        <ToastContainer hideProgressBar={true} newestOnTop={true} theme="colored"/>          
    );
});