import { getConfigs } from "../config";
const config = getConfigs();
let formatting_options = {
  style: "currency",
  currency: config.currency,
  minimumFractionDigits: 1,
};
export const toCurrencyFormat = (value: number|undefined) => {
  if (!value) {
    value = 0;
  }
  return new Intl.NumberFormat(config.locale, formatting_options).format(value);
};

export const toPercent = (value: number) => {
  let formattedNumber = value.toFixed(2);
  return formattedNumber;
};
