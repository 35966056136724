import { useEffect, useState } from "react";
import { Card, PrimaryButton, PrimaryButtonOutline, Spinner } from "../../../Elements";
import { ReactComponent as Idea } from '../../../assets/images/idea.svg';
import { useApplicantGoalInfo, useApplicantLoanAccounts, useApplicantLoanInfo } from "../../applicant/api/getApplicantGoalInfo";
import { Finapps } from "./finapps";
import styled from "styled-components";
import { getConfigs } from "../../../config";
import { TrackingType } from "../../applicant/types";


const Heading = styled.div`
    position: relative;
    margin-bottom: 18px;
    min-height: 50px;
    display: flex;
    margin-left: 5px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const CardHeadingMain = styled.div`
    font-size: 24px;
    font-family: "Open Sans",sans-serif;
    font-weight: 700;
    color: #24275e;
    margin-bottom: 2px;
    margin-left: 10px;
`;

const CardBody = styled.div`
    background-color: #fef7e8;
    border: 0;
    margin-bottom: 15px;
    padding: 10px 20px;
`;

const CardBodyH5 = styled.h5`
    display: flex;
    align-items: center;
    margin: 0;
    color: #677491;
    font-size: 14px;
    line-height: 28px;
`;

const SectionHeading = styled.div`
    font-size: 16px;
    font-family: "Open Sans",sans-serif;
    font-weight: 600;
    color: #24275e;
    margin-bottom: 15px;
`;

const Footer = styled.div`
    text-align: left;
    margin-left: 15px;
    margin-top: 20px;
    padding: 15px 0 30px;
`;

export const ApplicantLoanAccountLink = () => 
{
    const applicantInfo = useApplicantLoanInfo();
    const loanAccountsQuery = useApplicantLoanAccounts(applicantInfo?.data?.status);
    
    const [isLoanTrackingEnabled, setEnableLoanTracking] = useState(false);
    const [isAccountLinkPage, setAccountLinkPage] = useState(false);
    const [isLinked, setLinked] = useState(false);   
    
    useEffect(() => {
        if(applicantInfo.isSuccess && applicantInfo?.data){
            let applicantId = getConfigs().applicants[0].applicantId;
            let enabled = applicantInfo.data.enabledApplicants.filter(it => it.referenceId == applicantId).length > 0;
            
            if(applicantInfo?.data?.status === "Registered"){ 
                
            }else{
                setEnableLoanTracking(false);
            }
      
            if(enabled && loanAccountsQuery.data){
                setEnableLoanTracking(true);
                if(loanAccountsQuery.data.length > 0){
                    setAccountLinkPage(true);
                    setLinked(true);
                }else{
                    setAccountLinkPage(false);
                }
            }
            
          }
    }, [applicantInfo?.data?.status, loanAccountsQuery?.data]);

    if(applicantInfo.isLoading || (!loanAccountsQuery.isIdle && loanAccountsQuery.isLoading)){
        return (
            <div className="d-flex justify-content-end">
              <Spinner />
            </div>
          );
    }

    const skipToApplication = () => {
        let skipToApplicationEvent = new CustomEvent("skipToApplication", {
            bubbles: true,
            cancelable: false,
            composed: true,
            detail: {
                skipToApplication: true
            }
          });
          window.dispatchEvent(skipToApplicationEvent);
    }

    
    return (
        <>
        {isLoanTrackingEnabled && !loanAccountsQuery.isLoading ? 
        <div style={{maxWidth: '1254px'}}>

            {(!isAccountLinkPage) ? 
            
            <>            
                <div className="application__section">
                <Heading>            
                        <CardHeadingMain>
                        Keep track of your loans
                        </CardHeadingMain>
                    </Heading>
                        <CardBody>
                        <div className="row">
                            <div className="col-md-12">
                            <CardBodyH5>
                            <Idea style={{ marginRight: '15px'}}/>
                                <span>
                                Embrace the convenience of open banking as you gain complete visibility into your loan.
                                </span>
                            </CardBodyH5>
                            </div>
                        </div>
                        </CardBody>
                        <SectionHeading>
                        Are you ready to proceed with linking your loan account?
                        </SectionHeading>
                    <div>
                    </div>
                    
                    <Footer>
                        <PrimaryButton variant="primary" style={{marginRight:'10px'}} onClick={() => setAccountLinkPage(true)}>
                        Link Bank Accounts
                        </PrimaryButton>
                        {/* <PrimaryButtonOutline onClick={skipToApplication}>
                            Skip to Application
                        </PrimaryButtonOutline> */}
                    </Footer>
                </div>
                </> : 
                <>
                    <Finapps shadowContainer='applicant-loan-link' trackingType={TrackingType.Loan} isLinked={isLinked} onSuccess={() => {}}/>
                    {/* <PrimaryButtonOutline onClick={skipToApplication}>
                        Skip to Application
                    </PrimaryButtonOutline> */}
                </>                
                }
            </div> : null}
        </>
    );
}