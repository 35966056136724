import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClientProvider } from 'react-query';

import { queryClient } from '../lib/react-query';
import React from 'react';

const ErrorFallback = () => {
  return (
    // <div
    //   className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
    //   role="alert"
    // >
    //   <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
    //   <button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
    //     Refresh
    //   </button>
    // </div>
    <div></div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          <i>Loading..</i>
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <>{children}</>
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  );
};
