import styled from "styled-components";
import { Balance } from "../types";
import { ProgressBar } from "../../../Elements/ProgressBar";
import { toCurrencyFormat } from "../../../utils/format";

const KeyValueLabel = styled.div`
  display: flex;
  flex-direction: column;  /* Stacks the key and value vertically */
  align-items: flex-start;  /* Aligns text to the left */
  margin-left: 20px;  /* Adds some left margin for spacing */
`;

const Key = styled.span`
  font-size: 12px;
  color: #9A9B9E;
  font-weight: 600; 
`;

const Value = styled.span`
  font-size: 14px;
  font-weight: 600; 
`;

export const GoalProgress = ({
    balance = {} as Balance
}) => {

    let progress1 = 0, progress2 = 0, pointer = 0, pointerLabel = "",pointerLabelEnd = null;

    if (balance && balance!.amount) {
        if (balance!.amount <= balance!.goal) {
            progress1 = (balance!.amount / balance!.goal) * 100;
            pointer = progress1;
            pointerLabel = toCurrencyFormat(balance!.amount).split('.')[0];
        } else {
            let totalAmount = 2 * balance!.goal;
            if (balance!.amount > 2 * balance!.goal) {
                totalAmount = 2.5 * balance!.goal;
            }
            progress1 =  (balance!.goal / balance!.amount) * 100;
            progress2 = 100;//(balance!.amount / totalAmount) * 100;
            pointer = progress1;
            pointerLabel = "Minimum Goal Reached";
        }
    }

    return (
        <>
            <KeyValueLabel>
                <Key>Goal:</Key>
                <Value>{balance ? toCurrencyFormat(balance!.goal ).split('.')[0] : "Not provided"}</Value>
            </KeyValueLabel>
            <KeyValueLabel>
                <Key>Saved:</Key>
                <Value>{balance && balance!.amount ? toCurrencyFormat(balance!.amount).split('.')[0] : "Not provided"}</Value>
            </KeyValueLabel>
            <ProgressBar progress1={progress1} progress2={progress2} pointer={pointer} pointerlabel={pointerLabel} style={{ width: '50%',
                marginLeft: '40px' }}></ProgressBar>
        </>
    );

};