import { useQuery } from "react-query";

import { axios } from "../../../lib/axios";
import { ExtractFnReturnType, QueryConfig } from "../../../lib/react-query";
import { OpenBankingConnection, OpenBankingDetail } from "../types";
import { getConfigs } from "../../../config";

const getApplicantId = (): string => {
  var applicantId = getConfigs().applicants.find(it => it.isPrimaryApplicant)?.applicantId;  
  if(!applicantId){
    applicantId = getConfigs().applicants[0].applicantId;
  }
  return applicantId;
}

const getLeadId = (): string => {
  return getConfigs().leadId;
}

export const getOpenBankingConnection = (): Promise<OpenBankingConnection> => {
  var applicantId = getApplicantId();
  return axios.get(`/open-banking/${applicantId}/connection?leadId=${getLeadId()}`);
};

export const saveOpenBankingDetails = (
  detail: OpenBankingDetail
): Promise<number> => {
  var primaryApplicantId = getApplicantId();
  return axios.post(`/goal-tracking/${primaryApplicantId}`, {
    detail,
  });
};

type QueryFnType = typeof getOpenBankingConnection;

type OpenBankingConnectionOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useOpenBankingConnection = (status:string | undefined,{
  config,
}: OpenBankingConnectionOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    enabled: (status != undefined && (status.toLowerCase() == "registered" || status.toLowerCase() == "achieved" || status.toLowerCase() == "loantracking")) ? true : false,
    queryKey: ["getOpenBankingConnection"],
    queryFn: () => getOpenBankingConnection(),
  });
};
