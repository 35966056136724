import React from "react";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as XSymbol } from '../../assets/images/x-symbol.svg';


export function Dialog(props: any) {

    const closeIconStyles = {
        cursor: 'pointer',
    };

    const headerStyles = {
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)'
    };

    const footerStyles = {
        boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)'
    };

    return (
        <Modal
            {...props}
            size={props.size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            fullscreen={props.fullscreen}
            contentClassName="rounded-0"
        >
            {(props.closeButton || props.title) && (
                <Modal.Header closeButton={props.closeButton} style={headerStyles}>
                    <Modal.Title id="contained-modal-title-vcenter" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                        {props.title}
                    </Modal.Title>
                    <div style={closeIconStyles} onClick={props.onHide}>
                        <XSymbol style={{width:'16px', height: '16px'}}></XSymbol>
                    </div>
                </Modal.Header>
            )}
            <Modal.Body>                
                {props.children}
            </Modal.Body>
            {props.footer && (
                <Modal.Footer style={footerStyles}>
                    <props.footer />
                </Modal.Footer>
            )}
        </Modal>
    );
}
