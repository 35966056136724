import {forwardRef, useEffect, useImperativeHandle } from "react";
import { UserExperienceFlowType } from "../../../hooks/Yodlee/types";
import useYodlee from "../../../hooks/Yodlee/useYodlee";
import {
  saveOpenBankingDetails,
  useOpenBankingConnection,
} from "../api/getOpenBankingConnectionDetails";
import { getConfigs } from "../../../config";
import { useApplicantGoalInfo } from "../../applicant/api/getApplicantGoalInfo";
import { TrackingType } from "../../applicant/types";

export interface FastLinkConfig {
  launch(): void;
}

export interface FastLinkProps {
  onCancel?: () => void;
  onSuccess?: () => void;
  trackingType: TrackingType
}

export const Connection = forwardRef<FastLinkConfig, FastLinkProps>(({onCancel = (() => {}) as any, onSuccess = (() => {}) as any, trackingType}, ref) => {
  const applicantInfo = useApplicantGoalInfo(trackingType);
  const connection = useOpenBankingConnection(applicantInfo?.data?.status);  

  const config = getConfigs();

  const { ready, init } = useYodlee({
    onSuccess: (data) => {      
    },
    onClose: () => {
      onSuccess && onSuccess();
    },
    onError: (error) => {
      console.error(JSON.stringify(error));
    },
    containerId: "container-fastlink",
    fastLinkOptions: {
      fastLinkURL: connection.data?.url!,
      userExperienceFlow: connection.data
        ?.configName! as UserExperienceFlowType,
      trustedAdvisors: [{
        role: 'mortgage_broker',
        name: config.broker?.name ? config.broker?.name : '',
        email: config.broker?.email ? config.broker?.email : '',
      }],
      token: {
        tokenType: "AccessToken",
        tokenValue: connection.data?.accessToken!,
      }
    },
  });

  useImperativeHandle(ref, () => ({
    launch() {
      init();
    }
  }));

  /*useEffect(() => {
    if (connection.data && ready) {
      init();
    }
  }, [connection.data, ready]);*/

  return (
    <div className="container">
      <div id="container-fastlink"></div>
      {!ready && "Loading..."}
    </div>
  );
});
