import { useEffect, useState } from "react";
import { TokenType, FinappsHookType } from "./types";

const useFinapps: FinappsHookType = ({
  shadowContainer,
  containerId,
  createScriptTag = true,
  finappsOptions: { wellnessURL, token },
  onError,
  onEvent,
}) => {

  const [ready, setReady] = useState(false);
  const [error, setError] = useState<string>();
  const [data, setData] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    let script: HTMLScriptElement;
    if (createScriptTag) {
      script = document.createElement("script");

      script.id = "yodlee-finapps-script";
      //TODO: Move to config
      script.src = "https://test-standardflows-effi.s3.ap-southeast-2.amazonaws.com/ApplicantGoalTracking/scripts/finapps.initialize.min.js";//"https://finapp.effipfmstage.yodlee.com.au/wellness/v1/initialize.min.js";
      script.async = true;
      script.defer = true;
      script.onload = () => setReady(true);
      script.onerror = () =>
        setError("Yodlee Finapps library could not be loaded!");
      
      document.body.appendChild(script);
    }

    return () => {      
      if (createScriptTag) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const init = (currentToken?: TokenType) => {
    const getTokenString = (t: TokenType | undefined) => {
      switch (t?.tokenType) {
        case "AccessToken": {
          return { accessToken: `Bearer ${t?.tokenValue}` };
        }
        case "JwtToken": {
          return { jwtToken: `Bearer ${t?.tokenValue}` };
        }
      }
    };

    setActive(true);

    window.YDL?.WellnessApp.initialize(
      {
        containerId,
        shadowContainer,
        wellnessURL,
        iframeAttrs: {height: 1000},
        ...getTokenString(currentToken || token),       
        onError: (finappError: any) => {
          console.error(finappError);
          setError(finappError);
          onError && onError(finappError);
        },
        onEvent: (event: any) => {
          onEvent && onEvent(event)
        },
      },
      
    );

    window.YDL?.WellnessApp.launchApp('wellness');
  };

 

  return {
    init,
    data,
    error,
    ready,
    active,
  };
};

export default useFinapps;
