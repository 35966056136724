import { useEffect, useRef, useState } from "react";
import useFinapps from "../../../hooks/Yodlee/useYodleeFinapps";
import {
  useOpenBankingConnection,
} from "../api/getOpenBankingConnectionDetails";
import { Spinner } from "react-bootstrap";
import { useApplicantGoalInfo } from "../../applicant/api/getApplicantGoalInfo";
import { CustomButton, Dialog } from "../../../Elements";
import { Connection, FastLinkConfig } from "./connection";
import { TrackingType } from "../../applicant/types";

export const Finapps = ({
  shadowContainer = '' as string,
  isLinked = false as boolean, 
  onSuccess = (() => {}) as any,
  trackingType = TrackingType.None as any
}) => {
  const applicantInfo = useApplicantGoalInfo(trackingType);
  const connection = useOpenBankingConnection(applicantInfo?.data?.status);
  const formRef = useRef<HTMLFormElement>(null);  
  const [fastLinkShow, setfastLinkShow] = useState(false);
  const [finappsLoaded, setFinappsLoaded] = useState(false);

  const fastLinkRef = useRef<FastLinkConfig>(null);

  const handleError = (error: any) => {
    console.error(error);
    if(!error){
      return;
    }

    /**
     * error.eventData = 'error message'
     * error.eventName = 'error'
     */

    /*if(customerData.data.fnToCall ==="accountStatus"){
			accountStatus(customerData);	
		}
    else if(customerData.data.fnToCall === "renewClientSession"){      
      
		}
    else if(customerData.data.fnToCall ==="ssoErrorHandle"){
      //Refreshing on SSO error
      if(customerData.data.errorCode == 1013){
        init();
      }
    }else if(customerData.data.fnToCall === "errorHandler"){
      //TODO: Display error message
    }*/
  }

  const handleEvent = (event: any) => {
    console.log(event);
    console.log(JSON.stringify(event));
    if(event && event.eventData && (event.eventData.errorCode == 'W503' || event.eventData.errorCode == 'W505')){
      /*setTimeout(() => {
        init();          
      },500);*/
      connection.refetch();
    }
  }

  const { ready, init } = useFinapps({
    shadowContainer: shadowContainer,
    containerId: "container-finapps",
    finappsOptions: {
      wellnessURL: connection.data?.finappsUrl!,
      token: {
        tokenType: "AccessToken",
        tokenValue: connection.data?.accessToken!,       
      },          
    },
    onError: handleError,
    onEvent: handleEvent
  });

  useEffect(() => {

    if(!connection.isLoading && connection.data && ready){
        setTimeout(() => {
          setFinappsLoaded(true);
          /*logout(() => {
            
          });*/
          init();
          if(!isLinked && !fastLinkShow && !finappsLoaded){
            linkAccount();
          }
        },2000);
    }
  }, [applicantInfo?.data?.status, connection?.data, connection.isLoading, connection.isFetching, ready]);

  if (applicantInfo.isLoading || (!connection.isIdle && connection.isLoading)) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  

 /* if (window.addEventListener) {
    window.addEventListener("message", handlePostMessage, false);
  }*/

  //var pfmExtraParams = `iframeResize=true&locationurl=${window.location.origin}`;

  const accountStatus = (customerData: any) => {
    
  }

 const linkSuccess= () => {
    linkAccount();
    if(onSuccess){
      onSuccess();
    }
 }

 const logout = (callback: () => void) => {
  const url = new URL(connection.data?.url!);
  fetch(`${url.protocol}//${url.hostname}/logout/effi/fastlink`).finally(() => {
    callback()
  });
 }

 const linkAccount = () => {    
      if(fastLinkShow){
        setfastLinkShow(false);     
        //connection.refetch();   
        setTimeout(() => {
          init();          
        },500);
      }else{        
        setfastLinkShow(true);
        setTimeout(() => {
          fastLinkRef.current?.launch();
        }, 1200);
      }
 }

  return (
   
    <>
     {/* <form ref={formRef} action={connection.data?.finappsUrl} method="post" target="yodlee-iframe">
            <input type="hidden" name="accessToken" id="token" value={'Bearer '+connection.data?.accessToken}/>
            <input type="hidden" name="app" id="app" value="10003700"></input>
            <input type="hidden" name="extraParams" id="extraParams" value={pfmExtraParams} />
      </form> */}     

      <div className="row font-weight-bold mb-3" style={{float: 'right'}}>
        <div className="row-md-1">
          {finappsLoaded ? <CustomButton primarycolor="#0074bd" onClick={linkAccount}>
          <i className="bi bi-link-45deg"></i>
              Link Account
          </CustomButton> : null }
        </div>
      </div>   
      {/* <iframe id="yodlee-iframe" name="yodlee-iframe" width="100%" height="600" style={{border: '0px'}}></iframe> */}
      <div>
      <div id="container-finapps"></div>
        {!ready && "Loading..."}
       </div>
      <Dialog
            show={fastLinkShow}
            fullscreen={false}
            className="applicant-goal-tracking-modal"
            onHide={() => linkAccount()}
            title="Link Accounts"
            size="lg"
                >
         <Connection ref={fastLinkRef} onSuccess={linkSuccess} trackingType={trackingType}/>
      </Dialog>
    </>    
  );
};
