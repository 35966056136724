import React from "react";
import * as Component from "react-bootstrap/Spinner";

export function Spinner({ className = "wr-spinner" }) {
  return (
    <div className={className}>
      <Component.default animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Component.default>
    </div>
  );
}
