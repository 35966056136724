import React from 'react';
import { getTheme } from "../../config";
import styled from "styled-components";

const ProgressDiv = styled.div`
  position: relative;
  width: 100%;
`;

const ProgressBarContainer = styled.div`
  position: relative;
  height: 10px !important;
  background-color: #ddd !important;
  overflow: hidden;
  border-radius: 8px !important;
`;

const ProgressBarDiv = styled.div<{ width: string, color: string }>`
  position: absolute;
  left: 0;
  width: ${props => props.width};
  background-color: ${props => props.color} !important;
  height: 100%;
  border-radius: 8px !important;
`;

const PointerContainer = styled.div<{ position: string }>`
  position: absolute;
  left: ${props => props.position};
  top: 100%;
  transform: translateX(-50%);
  width: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PointerDiv = styled.div`
  width: 10px;
  height: 10px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: black;
`;

const PointerLabel = styled.div`
  font-size: 12px;
  white-space: nowrap;
`;

export const ProgressBar = (props: any) => {
    const theme = getTheme();
    const primaryProgress = parseFloat(props.progress1);
    const secondaryProgress = parseFloat(props.progress2);
    const pointer = parseFloat(props.pointer); // get the minimum goal from props
    const pointerLabel = props.pointerlabel;

    const difference = pointer - primaryProgress;
    const pointerLabelText = primaryProgress >= pointer
        ? "Minimum Goal Reached"
        : difference <= 5 // check if the difference is within 5%
            ? "Almost there"
            : "Minimum Goal";

    return (
        <ProgressDiv {...props}>
            <ProgressBarContainer className="progress">
                <ProgressBarDiv
                    style={{opacity: 0.3}}
                    width={secondaryProgress + '%'}
                    color={theme.primaryColor}
                />
                <ProgressBarDiv
                    width={primaryProgress + '%'}
                    color={theme.primaryColor}
                />
            </ProgressBarContainer>
            <PointerContainer position={pointer + '%'}> {/* position the pointer at the minimum goal */}
                <PointerDiv></PointerDiv>
                <PointerLabel>{pointerLabel}</PointerLabel>
            </PointerContainer>            
        </ProgressDiv>
    );
};
