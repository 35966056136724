import { useEffect, useState } from "react";
import { Card, PrimaryButton, PrimaryButtonOutline, Spinner } from "../../../Elements";
import { ReactComponent as Idea } from '../../../assets/images/idea.svg';
import { getBalance, useApplicantGoalInfo, useApplicantLoanAccounts } from "../../applicant/api/getApplicantGoalInfo";
import { Balance, LoanAccount, TrackingType } from "../../applicant/types";
import { Finapps } from "./finapps";
import '../css/openBanking.css';

import styled from 'styled-components';
import { GoalProgress } from "../../applicant/components/GoalProgress";
import { getConfigs } from "../../../config";

const Heading = styled.div`
    position: relative;
    margin-bottom: 18px;
    min-height: 50px;
    display: flex;
    margin-left: 5px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const CardHeadingMain = styled.div`
    font-size: 24px;
    font-family: "Open Sans",sans-serif;
    font-weight: 700;
    color: #24275e;
    margin-bottom: 2px;
    margin-left: 10px;
`;

const CardBody = styled.div`
    background-color: #fef7e8;
    border: 0;
    margin-bottom: 15px;
    padding: 10px 20px;
`;

const CardBodyH5 = styled.h5`
    display: flex;
    align-items: center;
    margin: 0;
    color: #677491;
    font-size: 14px;
    line-height: 28px;
`;

const SectionHeading = styled.div`
    font-size: 16px;
    font-family: "Open Sans",sans-serif;
    font-weight: 600;
    color: #24275e;
    margin-bottom: 15px;
`;

const Footer = styled.div`
    text-align: left;
    margin-left: 15px;
    margin-top: 20px;
    padding: 15px 0 30px;
`;

const TrackingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
  background: #F9FBFF;
  padding: 20px;  /* Adds padding inside the container */
  min-height: 80px;  /* Optional: Sets a minimum height */
`;

export const ApplicantSavingsAccountLink = () => 
{
    const applicantInfo = useApplicantGoalInfo(TrackingType.Savings);

    const [isGoalTrackingEnabled, setEnableGoalTracking] = useState(false);
    const [isAccountLinkPage, setAccountLinkPage] = useState(false); 
    const [isLinked, setLinked] = useState(false);   
    const [balance, setBalance] = useState<Balance>();

    
    useEffect(() => {
        if(applicantInfo.isSuccess && applicantInfo?.data){  
            
            let applicantId = getConfigs().applicants[0].applicantId;
            let enabled = applicantInfo.data.enabledApplicants.filter(it => it.referenceId == applicantId).length > 0;

            if(enabled && (applicantInfo?.data?.status === "Registered" || applicantInfo?.data?.status === "Achieved")){ //applicantInfo?.data?.status === "Registered" || 
                getBalance().then((balance) => {
                    setBalance(balance);
                    setEnableGoalTracking(true);
                    if(balance.amount){
                        setAccountLinkPage(true);
                        setLinked(true);
                    }
                });                
            }else{
                setEnableGoalTracking(false);
            }
          }
    }, [applicantInfo?.data?.status]);

    if(applicantInfo.isLoading){
        return (
            <div className="d-flex justify-content-end">
              <Spinner />
            </div>
          );
    }

    const skipToApplication = () => {
        let skipToApplicationEvent = new CustomEvent("skipToApplication", {
            bubbles: true,
            cancelable: false,
            composed: true,
            detail: {
                skipToApplication: true
            }
          });
          window.dispatchEvent(skipToApplicationEvent);
    }

    const continueToFinapp = () => {
        setAccountLinkPage(true);
    }


    return (
        <>
        {isGoalTrackingEnabled ? 
             <div style={{maxWidth: '1254px'}}>
            {(!isAccountLinkPage) ?
              
            <div>
                <Heading>
            
                    <CardHeadingMain>
                    Manage your money like a pro
                    </CardHeadingMain>
                </Heading>
                    <CardBody>
                    <div className="row">
                        <div className="col-md-12">
                        <CardBodyH5>
                        <Idea style={{ marginRight: '15px'}}/>
                           <span>
                                With the power of Open Banking, link your loan and/or bank accounts to harness the full potential of our Personal Finance Manager. 
                                Access greater visibility and insights, create a budget, set a savings goal, or have your loan automatically monitored by your broker 
                                to ensure you are always notified early when you could be getting better value. Securely linking your accounts via your bank's portal 
                                takes less than a minute and will provide you with ongoing value that can last a lifetime. Best of all, this is a service we offer to our 
                                clients completely free of charge. Visit <a href="https://www.cdr.gov.au/what-is-cdr">www.cdr.gov.au/what-is-cdr</a> for more information.
                            </span>
                        </CardBodyH5>
                        </div>
                    </div>
                    </CardBody>
                    <SectionHeading>
                    Are you ready to proceed with linking your accounts?
                    </SectionHeading>
                <div>
                </div>
                
                <Footer>
                    <PrimaryButton variant="primary" style={{marginRight:'10px'}} onClick={continueToFinapp}>
                    Continue
                    </PrimaryButton>
                    <PrimaryButtonOutline onClick={skipToApplication}>
                        Skip to Application
                    </PrimaryButtonOutline>
                </Footer>
                </div> 
                : 
                <>
                    <div className="row mb-3">
                        <TrackingContainer>
                            <GoalProgress balance={balance}></GoalProgress>    
                        </TrackingContainer>                   
                    </div>  
                    <Finapps shadowContainer='applicant-savings-link' trackingType={TrackingType.Savings} isLinked={isLinked} onSuccess={() => {
                        getBalance().then((balance) => {
                            setBalance(balance);
                        });
                    }}/>                  
                     <PrimaryButtonOutline className='mt-3' onClick={skipToApplication}>
                        Skip to Application
                    </PrimaryButtonOutline>
                </> }
                 </div>
                : null}
            </>
    );
}