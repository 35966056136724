import React from "react";
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";
import NumericInput from "react-numeric-input";
import styled from "styled-components";
import { getTheme } from "../../config";

export type NumericFormatConfig = {
  defaultValue?: any;
  control: any;
  className: string;
  placeholder?: string;
  register?: any;
  name: string;
  rules: any;
};

export type NumericInputConfig = {
  defaultValue?: number;
  min?: number;
  max?: number;
  control: any;
  name: string;
  className: string;
};
const theme = getTheme();
const StyledNumericInput = styled(NumericInput)`
    border: 1px solid #98a7c8;
    border-radius: 4px !important;
    color: ${theme.secondaryColor};
    font-weight: 600;
    font-size: 14px !important;
    height: 40px;
    max-width: 75px;
    padding: 10px 0.75rem !important;

    &:focus {
      box-shadow: none;
      border: 1px solid ${theme.secondaryColor} !important;
    }

    &::placeholder {
      font-size: 12px;
      @include font-semibold;
      line-height: 19px;
      color: #98a7c8;
    }
`;
export const NumericFormatController = ({
  defaultValue,
  control = null,
  className,
  placeholder = "",
  name,
  rules
}: NumericFormatConfig) => {
  return (
    <Controller
      control={control}
      defaultValue={""+defaultValue}     
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <NumericFormat
          getInputRef={ref}
          onValueChange={(values) => {
            onChange(values.floatValue);
          }}
          value={value || ""}
          prefix={""} // removed the dollar sign as the text box itself has it according to the mockup
          className={className}
          allowLeadingZeros
          thousandSeparator=","
          placeholder={placeholder}
        />
      )}
    />
  );
};

export const NumericInputController = ({
  defaultValue,
  control = null,
  name,
  className,
  min,
  max
}: NumericInputConfig) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <StyledNumericInput
          className={className}
          strict={true}
          ref={ref}
          min={min || 0}
          max={max || 100}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};
