import { useEffect } from "react"
import { useApplicantGoalInfo } from "../../applicant/api/getApplicantGoalInfo";
import { getConfigs } from "../../../config";
import { TrackingType } from "../../applicant/types";

export const GetGoalTrackingSettings = () => 
{
    const applicantInfo = useApplicantGoalInfo(getConfigs().accountType == 'savings' ? TrackingType.Savings : TrackingType.Loan);
    
    useEffect(() => {
        if(applicantInfo.isSuccess && applicantInfo?.data)
        {
          let applicantId = getConfigs().applicants[0].applicantId;

          let enabled = applicantInfo.data.enabledApplicants.filter(it => it.referenceId == applicantId).length > 0;

            let goalTrackerEnabledEvent = new CustomEvent("goalTrackerSettings", {
                bubbles: true,
                cancelable: false,
                composed: true,
                detail: {
                  goalTrackingEnabled: enabled ? (applicantInfo?.data?.status === "Registered" || applicantInfo?.data?.status === "Achieved") : false,
                  loanTrackingEnabled: enabled ? (applicantInfo?.data?.status === "Registered") : false
                }
              });

              window.dispatchEvent(goalTrackerEnabledEvent);
        }

    }, [applicantInfo?.data?.status]);

    return (<></>);
}