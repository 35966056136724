import React from 'react';
import { getTheme } from "../../config";
import styled from "styled-components";
import Spinner from 'react-bootstrap/Spinner';

export const PrimaryButton = (props: any) => {
  const theme = getTheme();
  const Button = styled.button`
    border-radius: 4px;
    padding: 7px 20px;
    font-size: 14px;
    border: 2px solid ${props.disabled? 'silver': theme.primaryColor};
    background-color: ${props.disabled? 'silver': theme.primaryColor};
    min-width: 60px;
    color: white;
    &:hover {
        background-color: ${props.disabled? 'silver': 'white'};
        border-color: ${props.disabled? 'silver !important': theme.primaryColor};
        color: ${props.disabled? 'white': theme.primaryColor};
      }
  `;

  return (<Button disabled={(props.loading == 'true'? 'disabled' : null) || props.disabled} {...props}>{props.loading == 'true' ?  
  <>
   {props.children}{' '}
    <Spinner 
        as="span"
        animation="border"
        size="sm"
        variant="light"
    />
  </>
   : props.children}</Button>)
};

export const PrimaryButtonOutline = (props: any) => {
    const theme = getTheme();
    const Button = styled.button`
      border-radius: 3px;
      padding: 7px 20px;
      font-size: 14px;
      border-radius: 4px;
      min-width: 60px;
      border: 1px solid ${props.disabled? 'silver !important': theme.primaryColor};
      background-color: white;
      color: ${props.disabled? 'silver  !important': theme.primaryColor};
      &:hover {
        background-color: ${props.disabled? 'silver !important': theme.primaryColor};
        border-color: ${props.disabled? 'silver !important': theme.primaryColor};
        color: white;
      }
    `;
  
    // return (<Button {...props}>{props.children}</Button>)
    return (<Button disabled={(props.loading == 'true'? 'disabled' : null) || props.disabled} {...props}>{props.loading == 'true' ?  
    <>
     {props.children}{' '}
        <Spinner 
            as="span"
            animation="border"
            size="sm"
            style={{color: theme.primaryColor}}      
        />
    </>
     : props.children}</Button>)
  };

  export const CustomButton = (props: any) => {
    const Button = styled.button`
      border-radius: 4px;
      padding: 7px 20px;
      font-size: 14px;
      border: 2px solid ${props.disabled? 'silver': props.primarycolor};
      background-color: ${props.disabled? 'silver': props.primarycolor};
      min-width: 60px;
      color: white;
      &:hover {
        background-color: ${props.disabled? 'silver': 'white'};
        border-color: ${props.disabled? 'silver !important': props.primarycolor};
        color: ${props.disabled? 'white': props.primarycolor};
      }
    `;
  
    return (<Button {...props}>{props.children}</Button>)
  };
  
