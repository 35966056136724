import React from "react";
import * as ReactDOM from "react-dom/client";
import reactToWebComponent from "react-to-webcomponent";
import {GetGoalTrackSettingsApp, LoanLinkingApp, RapidRepayApp, SavingsLinkingApp} from "./App.applicant";
import './index.css';

// const applicantFastLink = reactToWebComponent(App, React, ReactDOM, {
//   props: {
//     options: "json",
//     theme: "json"
//   }
// });
// customElements.define("applicant-fastlink", applicantFastLink);


// const applicantFinapps = reactToWebComponent(FinAppsApp, React, ReactDOM, {
//   props: {
//     options: "json",
//     theme: "json"
//   },
//   shadow: "open"
// });

// customElements.define("applicant-finapps", applicantFinapps);


const applicantLoanLink = reactToWebComponent(LoanLinkingApp, React, ReactDOM, {
  props: {
    options: "json",
    theme: "json"
  },
  shadow: "open"
});

customElements.define("applicant-loan-link", applicantLoanLink);

const applicantSavingsLink = reactToWebComponent(SavingsLinkingApp, React, ReactDOM, {
  props: {
    options: "json",
    theme: "json"
  },
  shadow: "open"
});

customElements.define("applicant-savings-link", applicantSavingsLink);

const getGoalTrackingSettings = reactToWebComponent(GetGoalTrackSettingsApp, React, ReactDOM, {
  props: {
    options: "json"
  },
  shadow: "open"
});

customElements.define("goal-tracking-settings", getGoalTrackingSettings);

const rapidRepay = reactToWebComponent(RapidRepayApp, React, ReactDOM, {
  props: {
    options: "json"
  },
  shadow: "open"
});

customElements.define("rapid-repay", rapidRepay);
